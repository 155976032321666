import React from 'react'
import Form from '../Includes/Wrapper/Form'
import Root from '../Includes/Wrapper/Root'
import Footer from '../Includes/Footer/Footer'
import InputMail from '../Includes/Input/InputMail'
import history from '../../Navigation/Includes/History'
import LoginButton from '../Includes/Login/LoginButton'
import InputPassword from '../Includes/Input/InputPassword'
import {
  useDispatch
} from 'react-redux'
import {
  Link,
  Paper,
  Typography
} from '@mui/material'
import {
  CheckMail,
  CheckPassword
} from '../../Functions/Check'
import {
  landing
} from '../../Variables/Landing'
import {
  config
} from '../../Variables/Variables'
import {
  PENDING,
  USER_LOGIN
} from '../../Redux/Actions/Login'

const Login = () => {
  const dispatch = useDispatch()
  const [error, setError] = React.useState({
    login: false,
    password: false
  })
  const [state, setState] = React.useState({
    login: '',
    password: ''
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const UpdateError = (item, value) => {
    setError(current => ({
      ...current,
      [item]: value
    }))
  }
  const Login = (value) => {
    Update('login', value)
    UpdateError('login', (
      (value !== '') &&
      (!CheckMail(value))
    ))
  }
  const Password = (value) => {
    Update('password', value)
    UpdateError('password', (
      (value !== '') &&
      (!CheckPassword(value))
    ))
  }
  const Commit = () => {
    if (
      CheckMail(state.login) &&
      CheckPassword(state.password)
    ) {
      UpdateError('login', false)
      UpdateError('password', false)
      dispatch(PENDING(true))
      dispatch(USER_LOGIN(
        {
          login: state.login,
          password: state.password
        },
        (die, url) => {
          (die)
            ? (url === null)
              ? history.push('/compte')
              : window.location.assign(url + '/compte')
            : (url === null)
              ? history.push('/accueil')
              : window.location.assign(url + '/accueil')
        }
      ))
    } else {
      UpdateError('login', !CheckMail(state.login))
      UpdateError('password', !CheckPassword(state.password))
    }
  }
  return (
    <Root className="login-content">
      <Paper className="login-paper">
        <Form fit={true}>
          <InputMail
            setValue={Login}
            error={error.login}
            value={state.login}
          />
          <InputPassword
            setValue={Password}
            label="Mot de passe"
            confirmation={false}
            error={error.password}
            value={state.password}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                Commit()
              }
            }}
          />
          <Link
            className="nav-link pointer"
            onClick={() => history.push('/forgot')}
          >
            <Typography className="center forgot">Mot de passe oublié ?</Typography>
          </Link>
          <LoginButton
            label="Connexion"
            onClick={() => Commit()}
          />
        </Form>
      </Paper>
      {
        (config.beta === '1')
          ? <Paper className="warning-paper">
              <div className="warning-paper-content warning-paper-beta">
                <div>Vous êtes sur la version Beta de {landing.nom}.</div>
                <div>Cet environnement est strictement reservé aux utilisateurs déclarés.</div>
                <div>En cas de doute, demandez à votre manager.</div>
              </div>
            </Paper>
          : (config.demo === '1')
            ? <Paper className="warning-paper">
                <div className="warning-paper-content warning-paper-demo">
                  <div>Vous êtes sur la version Demo de {landing.nom}.</div>
                  <div>Cet environnement est strictement reservé à notre utilisation.</div>
                  <div>Il ne contient que des données factices.</div>
                </div>
              </Paper>
            : (config.qualif === '1')
              ? <Paper className="warning-paper">
                  <div className="warning-paper-content warning-paper-qualif">
                    <div>Vous êtes sur l'environnement de qualification de {landing.nom}.</div>
                    <div>Cet environnement est strictement reservé à notre utilisation.</div>
                    <div>Il ne contient que des données factices.</div>
                  </div>
                </Paper>
              : (config.dev === '1')
                ? <Paper className="warning-paper">
                    <div className="warning-paper-content warning-paper-dev">
                      <div>Vous êtes sur l'environnement de développement de {landing.nom}.</div>
                      <div>Cet environnement est strictement reservé à notre utilisation.</div>
                      <div>Il ne contient que des données factices.</div>
                    </div>
                  </Paper>
                : null
      }
      <Footer mini={true}/>
    </Root>
  )
}

export default Login
