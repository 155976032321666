import clsx from 'clsx'
import React from 'react'
import styles from './AbonnementFree.module.css'
import AbonnementSeparate from './AbonnementSeparate'
import {
  LocalOffer
} from '@mui/icons-material'
import {
  offer_time
} from '../../../Functions/Functions'

const AbonnementFree = ({
  days,
  free,
  mobile,
  favorite,
  abonnement
}) => {
  return (
    <>
      <AbonnementSeparate
        mobile={mobile}
        favorite={favorite}
      />
      <div
        className={
          clsx({
            [styles.mobile]: mobile,
            [styles.container]: true,
            [styles.favorite]: favorite
          })
        }
      >
        <LocalOffer className="free-icone"/>
        {
          (abonnement.free_days > 0)
            ? (free)
              ? (mobile)
                ? <span className={styles.span}>{offer_time(days)}</span>
                : <>
                    <span
                      className={
                        clsx(
                          styles.old,
                          styles.span
                        )
                      }
                    >
                      {offer_time(abonnement.free_days)}
                    </span>
                    <span
                      className={
                        clsx(
                          styles.new,
                          styles.span
                        )
                      }
                    >
                      {offer_time(days)}
                    </span>
                  </>
              : <span className={styles.span}>{offer_time(abonnement.free_days)}</span>
            : (free)
              ? <span className={styles.span}>{offer_time(days)}</span>
              : null
        }
      </div>
    </>
  )
}

export default AbonnementFree
