import React from 'react'
import Form from '../Includes/Wrapper/Form'
import InputTime from '../Includes/Input/InputTime'
import InputMotif from '../Includes/Input/InputMotif'
import DatePicker from '../Includes/Picker/DatePicker'
import SelectSens from '../Includes/Select/SelectSens'
import SelectMotif from '../Includes/Select/SelectMotif'
import SelectSalarie from '../Includes/Select/SelectSalarie'
import SliderCreneau from '../Includes/Slider/SliderCreneau'
import SelectRecuperation from '../Includes/Select/SelectRecuperation'
import {
  useSelector
} from 'react-redux'
import {
  CheckInt,
  CheckLong
} from '../../Functions/Check'
import {
  format_minute
} from '../../Functions/Heures'

const HeureModalContent = ({
  error,
  state,
  Commit,
  Update,
  UpdateError
}) => {
  const user = useSelector(state => state.login.user)
  const modal = useSelector(state => state.heures.modal)
  const settings = useSelector(state => state.settings.variables)
  const Motif = (value) => {
    Update('texte', value)
    UpdateError('motif', (
      (value !== '') &&
      (!CheckLong(value))
    ))
  }
  const Heures = (value) => {
    const nombre = format_minute({
      heure: value,
      minute: state.minutes
    })
    Update('heures', value)
    Update('nombre', nombre)
    UpdateError('nombre', (
      (parseInt(nombre) < settings.minimum.supp) ||
      (parseInt(nombre) > settings.maximum.supp) ||
      (parseInt(state.minutes) >= 60) ||
      (!CheckInt(state.minutes)) ||
      (!CheckInt(value))
    ))
  }
  const Minutes = (value) => {
    const nombre = format_minute({
      minute: value,
      heure: state.heures
    })
    Update('minutes', value)
    Update('nombre', nombre)
    UpdateError('nombre', (
      (parseInt(nombre) < settings.minimum.supp) ||
      (parseInt(nombre) > settings.maximum.supp) ||
      ((parseInt(nombre) % 15) !== 0) ||
      (!CheckInt(state.heures)) ||
      (parseInt(value) >= 60) ||
      (!CheckInt(value))
    ))
  }
  return (
    <Form full={!modal.nombre}>
      {
        (user.profil === 'administrateur')
          ? <SelectSalarie
              value={state.salarie}
              setValue={(value) => Update('salarie', value)}
            />
          : null
      }
      <SelectSens
        value={state.sens}
        setValue={(value) => Update('sens', value)}
      />
      <SelectMotif
        value={state.motif}
        array={settings.heure}
        setValue={(value) => Update('motif', value)}
      />
      {
        (state.motif === (settings.heure.motifs.length - 1))
          ? <InputMotif
              setValue={Motif}
              error={error.motif}
              value={state.texte}
            />
          : null
      }
      <SelectRecuperation
        value={state.recuperation}
        setValue={(value) => Update('recuperation', value)}
      />
      <DatePicker
        value={state.date}
        open={state.picker}
        onClose={() => Update('picker', false)}
        onChange={(value) => Update('date', value)}
        slotProps={{
          textField: {
            onClick: () => Update('picker', true)
          }
        }}
      />
      {
        (modal.nombre !== undefined)
          ? (modal.nombre)
            ? <InputTime
                state={state}
                setHeures={Heures}
                setMinutes={Minutes}
                error={error.nombre}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    Commit()
                  }
                }}
              />
            : <SliderCreneau
                error={error}
                state={state}
                Update={Update}
                UpdateError={UpdateError}
                buttons={(modal.open !== 'edit')}
              />
          : null
      }
    </Form>
  )
}

export default HeureModalContent
