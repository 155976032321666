import React from 'react'
import SelectInput from './SelectInput'

const SelectMotif = ({
  array,
  ...others
}) => {
  return (
    <SelectInput
      label="Motif"
      options={array.motifs.map((item, index) => {
        return {
          value: index,
          label: item
        }
      })}
      {...others}
    />
  )
}

export default SelectMotif
