import React from 'react'
import Image from '../Includes/Image'
import Texte from '../Includes/Texte'
import Container from '../Includes/Container'
import Source from '../../Images/absences.png'
import {
  useSelector
} from 'react-redux'

const Absences = () => {
  const device = useSelector(state => state.device)
  return (
    <Container reverse={device.mobile}>
      <Image
        width={70}
        src={Source}
        shadow={true}
        alt="Absences"
      />
      <Texte
        right={true}
        titre="Gérez les #absences.#"
        description="Centralisez, triez et traitez toutes les demandes d'absences de vos salariés."
      />
    </Container>
  )
}

export default Absences
