import clsx from 'clsx'
import React from 'react'
import AbonnementBuy from './AbonnementBuy'
import styles from './Abonnement.module.css'
import AbonnementFree from './AbonnementFree'
import AbonnementPrix from './AbonnementPrix'
import AbonnementLight from './AbonnementLight'
import AbonnementFeatures from './AbonnementFeatures'
import AbonnementSeparate from './AbonnementSeparate'

const Abonnement = ({
  Buy,
  days,
  free,
  error,
  modal,
  mobile,
  Select,
  content,
  current,
  payment,
  favorite,
  selected,
  promotion,
  abonnement
}) => {
  const props = {
    mobile: mobile,
    favorite: favorite,
    selected: selected,
    abonnement: abonnement
  }
  const essai = (
    (
      (abonnement.free_days > 0) &&
      (current === undefined)
    ) ||
    (
      (days > 0) &&
      (free)
    )
  )
  return (
    <div
      onClick={() => {
        if (
          (!error) &&
          (Select)
        ) {
          Select(abonnement)
        }
      }}
      className={
        clsx({
          [styles.error]: error,
          [styles.modal]: modal,
          [styles.mobile]: mobile,
          [styles.payment]: payment,
          [styles.abonnement]: true,
          [styles.favorite]: favorite,
          [styles.selected]: selected,
          [styles.border]: (Buy === undefined),
          [styles.hover]: (
            (!error) &&
            (!payment) &&
            (!selected) &&
            (Buy === undefined)
          )
        })
      }
    >
      <AbonnementLight
        top={true}
        {...props}
      />
      <AbonnementPrix
        promotion={promotion}
        {...props}
      />
      {
        (essai)
          ? <AbonnementFree
              days={days}
              free={free}
              {...props}
            />
          : null
      }
      <AbonnementSeparate {...props}/>
      <AbonnementFeatures
        error={error}
        modal={modal}
        content={content}
        {...props}
      />
      {
        (Buy)
          ? <AbonnementBuy
              onClick={() => Buy(abonnement)}
              {...props}
            />
          : <AbonnementLight
              bottom={true}
              {...props}
            />
      }
    </div>
  )
}

export default Abonnement
