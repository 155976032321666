import dayjs from '../../Libraries/dayjs'
import AbsenceModalContent from './AbsenceModalContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  sort_name
} from '../../Functions/Sort'
import {
  CheckLong
} from '../../Functions/Check'
import {
  isDifferent
} from '../../Functions/Compare'
import {
  get_fin,
  get_debut,
  get_conges_number_length
} from '../../Functions/Absences'
import {
  get_id
} from '../../Functions/Functions'
import {
  initial
} from '../../Variables/Variables'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  EDIT_ABSENCE,
  CREATE_ABSENCE,
  MODAL_ABSENCES
} from '../../Redux/Actions/Absences'

const AbsenceModal = () => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const user = useSelector(state => state.login.user)
  const modal = useSelector(state => state.absences.modal)
  const equipe = useSelector(state => state.equipe.equipe)
  const filters = useSelector(state => state.filters.filters)
  const params = useSelector(state => state.settings.settings)
  const absences = useSelector(state => state.absences.absences)
  const settings = useSelector(state => state.settings.variables)
  const [state, setState] = React.useState({
    select: 0,
    error: false,
    disabled: false,
    values: undefined,
    absence: undefined,
    motif: initial.absence.motif,
    texte: initial.absence.texte,
    dates: (
      (modal.data !== undefined) &&
      (modal.data.jour !== undefined)
    )
      ? modal.data.jour
      : initial.absence.dates,
    duree: (
      (modal.data !== undefined) &&
      (modal.data.longueur !== undefined)
    )
      ? modal.data.longueur
      : initial.absence.duree,
    salarie: (modal.open === 'edit')
      ? absences.find(item => item.id === modal.id).user
      : (user.profil === 'administrateur')
        ? (filters.absences.salarie !== -1)
          ? filters.absences.salarie
          : (
              (modal.data !== undefined) &&
              (modal.data.salarie !== undefined)
            )
              ? modal.data.salarie
              : sort_name(equipe)[0].id
        : user.unique_id_user
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const Commit = () => {
    if (
      (
        (state.motif < (settings.absence.motifs.length - 1)) ||
        (
          (state.motif === (settings.absence.motifs.length - 1)) &&
          CheckLong(state.texte)
        )
      ) && (state.select > 0)
    ) {
      Update('absence', {
        duree: state.duree,
        motif: state.motif,
        user: state.salarie,
        nombre: state.select,
        fin: get_fin(state.dates[1], state.duree, true),
        debut: get_debut(state.dates[0], state.duree, true),
        id: (modal.id === '')
          ? get_id()
          : modal.id,
        demande: (modal.id === '')
          ? dayjs().unix()
          : state.values.demande,
        texte: (state.motif === (settings.absence.motifs.length - 1))
          ? state.texte
          : '',
        status: (modal.id === '')
          ? (
              (user.profil === 'administrateur') ||
              (params.find(item => item.id === 'validation').value === 0)
            ) ? 1 : 2
          : state.values.status
      })
    } else {
      Update('error', !CheckLong(state.texte))
    }
  }
  useEffect(() => {
    if (modal.open !== '') {
      Update('error', false)
      if (modal.id === '') {
        Update('motif', initial.absence.motif)
        Update('texte', initial.absence.texte)
        Update('dates', (
          (
            (modal.data !== undefined) &&
            (modal.data.jour !== undefined)
          )
            ? modal.data.jour
            : initial.absence.dates
        ))
        Update('duree', (
          (
            (modal.data !== undefined) &&
            (modal.data.longueur !== undefined)
          )
            ? modal.data.longueur
            : initial.absence.duree
        ))
        Update('salarie', (
          (user.profil === 'administrateur')
            ? (filters.absences.salarie !== -1)
              ? filters.absences.salarie
              : (
                  (modal.data !== undefined) &&
                  (modal.data.salarie !== undefined)
                )
                  ? modal.data.salarie
                  : sort_name(equipe)[0].id
            : user.unique_id_user
        ))
      } else {
        const absence = absences.find(item => item.id === modal.id)
        Update('values', {
          ...absence,
          fin: get_fin(absence.fin, absence.duree),
          debut: get_debut(absence.debut, absence.duree)
        })
      }
    }
    // eslint-disable-next-line
  }, [modal])
  useEffect(() => {
    const diff = (
      (state.dates[1] === null) ||
      (dayjs(state.dates[0]).format('DD/MM/YYYY') === dayjs(state.dates[1]).format('DD/MM/YYYY'))
    )
    if (!diff) {
      Update('duree', 2)
      Update('disabled', !diff)
    } else {
      Update('disabled', false)
    }
    Update('select', (
      get_conges_number_length(
        dayjs(state.dates[0]),
        dayjs(state.dates[1]),
        state.duree
      )
    ))
    // eslint-disable-next-line
  }, [state.duree, state.dates, state.salarie])
  useEffect(() => {
    if (state.values !== undefined) {
      Update('duree', state.values.duree)
      Update('motif', state.values.motif)
      Update('texte', state.values.texte)
      Update('salarie', state.values.user)
      Update('dates', [
        dayjs.unix(state.values.debut).startOf('day'),
        dayjs.unix(state.values.fin).startOf('day')
      ])
    }
    // eslint-disable-next-line
  }, [state.values])
  useEffect(() => {
    if (state.absence !== undefined) {
      if (modal.id !== '') {
        if (isDifferent(
          [{
            ...state.absence,
            fin: get_fin(state.absence.fin, state.absence.duree),
            debut: get_debut(state.absence.debut, state.absence.duree)
          }], [state.values])
        ) {
          dispatch(EDIT_ABSENCE(state.absence))
        }
      } else {
        dispatch(CREATE_ABSENCE(state.absence))
      }
      dispatch(MODAL_ABSENCES('', ''))
    }
    // eslint-disable-next-line
  }, [state.absence])
  return (
    <Dialog
      maxWidth="md"
      open={(
        (modal.open === 'create') ||
        (modal.open === 'edit')
      )}
      className={
        (device.mobile)
          ? 'dialog-absence-mobile'
          : 'dialog-absence'
      }
    >
      <DialogTitle>
        {
          (modal.id === '')
            ? "Nouvelle absence"
            : "Modifier l'absence"
        }
      </DialogTitle>
      <DialogContent>
        <AbsenceModalContent
          state={state}
          Update={Update}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => dispatch(MODAL_ABSENCES('', ''))
          },
          {
            onClick: () => Commit()
          }
        ]}
      />
    </Dialog>
  )
}

export default AbsenceModal
