import clsx from 'clsx'
import React from 'react'
import dayjs from '../../../Libraries/dayjs'
import LegendeAbsenceUser from './LegendeAbsenceUser'
import styles from './LegendeAbsenceContent.module.css'
import LegendeAbsenceContentLigne from './LegendeAbsenceContentLigne'
import {
  useSelector
} from 'react-redux'
import {
  start_case
} from '../../../Functions/Strings'

const LegendeAbsenceContent = ({
  absence,
  condition
}) => {
  const equipe = useSelector(state => state.equipe.equipe)
  const settings = useSelector(state => state.settings.variables)
  const user = equipe.find(item => item.id === absence.user)
  const days = (
    (absence.duree === 2) &&
    (dayjs.unix(absence.debut).format('DDMMYYYY') !== dayjs.unix(absence.fin).format('DDMMYYYY'))
  )
  return (
    <>
      <LegendeAbsenceUser
        user={user}
        condition={condition}
      />
      <LegendeAbsenceContentLigne className={styles.motif}>
        {
          (absence.motif < (settings.absence.motifs.length - 1))
            ? settings.absence.motifs[absence.motif]
            : absence.texte
        }
      </LegendeAbsenceContentLigne>
      <LegendeAbsenceContentLigne>
        <i className="icon clock"/>
        <span>
          {
            (absence.duree === 0)
              ? 'Matinée'
              : (absence.duree === 1)
                ? 'Après-midi'
                : 'Journée'
          }
        </span>
      </LegendeAbsenceContentLigne>
      <LegendeAbsenceContentLigne>
        <i
          className={
            clsx(
              'icon',
              (days)
                ? 'absence-from'
                : 'calendar'
            )
          }
        />
        <span>
          {start_case(dayjs.unix(absence.debut).format('dddd D MMMM YYYY'))}
        </span>
      </LegendeAbsenceContentLigne>
      {
        (days)
          ? <LegendeAbsenceContentLigne>
              <i className="icon absence-to"/>
              <span>
                {start_case(dayjs.unix(absence.fin).format('dddd D MMMM YYYY'))}
              </span>
            </LegendeAbsenceContentLigne>
          : null
      }
      {
        (condition === false)
          ? <div className={styles.margin}/>
          : null
      }
    </>
  )
}

export default LegendeAbsenceContent
