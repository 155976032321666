import {
  USER_LOGOUT
} from './Login'
import {
  UPDATE_CONGES_USER
} from './Equipe'
import {
  UPDATE_PLANNING
} from './Planning'
import {
  api
} from '../../API/api'
import {
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const ABSENCE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'ABSENCE',
      value: value
    })
  }
}

export const ADD_ABSENCE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_ABSENCE',
      value: value
    })
  }
}

export const INIT_ABSENCES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'INIT_ABSENCES',
      value: value
    })
  }
}

export const MODIFY_ABSENCE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'MODIFY_ABSENCE',
      value: value
    })
  }
}

export const REMOVE_ABSENCE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_ABSENCE',
      value: value
    })
  }
}

export const SET_DATE_ABSENCES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_DATE_ABSENCES',
      value: value
    })
  }
}

export const STATUS_ABSENCE = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: 'STATUS_ABSENCE',
      value: value,
      id: id
    })
  }
}

export const REMOVE_ABSENCES_USER = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_ABSENCES_USER',
      value: value
    })
  }
}

export const MODAL_ABSENCES = (id, open, data) => {
  return (dispatch) => {
    dispatch({
      type: 'MODAL_ABSENCES',
      data: data,
      open: open,
      id: id
    })
  }
}

export const DELETE_ABSENCE = (id) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch, state) => {
    const data = JSON.stringify({
      id: id,
      action: 'delete-absence'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          const absences = state().absences.absences
          const ancien = absences.find(item => item.id === id)
          dispatch(REMOVE_ABSENCE(id))
          if (ancien.status === 1) {
            dispatch(UPDATE_PLANNING())
          }
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const CUT_ABSENCES = (value) => {
  return (dispatch) => {
    if (value.length > 0) {
      const data = JSON.stringify({
        value: value,
        action: 'cut-absences'
      })
      api(data)
        .then(data => {
          if (data.status === 'OK') {
            value.forEach(item => dispatch(ADD_ABSENCE(item)))
          } else {
            if (data.data === 'die') {
              dispatch(USER_LOGOUT(false))
            }
            print_error(data.response)
          }
        })
        .catch(() => {
          print_error()
        })
    }
  }
}

export const EDIT_ABSENCE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: 'edit-absence'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(MODIFY_ABSENCE(value))
          if (value.status === 1) {
            dispatch(UPDATE_PLANNING())
          }
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const CREATE_ABSENCE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: 'create-absence'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(ADD_ABSENCE(value))
          if (value.status === 1) {
            dispatch(UPDATE_PLANNING())
          }
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const UPDATE_ABSENCE = (id, value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      id: id,
      value: value,
      action: 'update-absence'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(STATUS_ABSENCE(id, value))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const UPDATE_CLOSE_ABSENCE = (value) => {
  return (dispatch) => {
    if (value.length > 0) {
      const data = JSON.stringify({
        value: value,
        action: 'close-absence-comptabilite'
      })
      api(data)
        .then(data => {
          if (data.status === 'OK') {
            value.forEach(item => dispatch(STATUS_ABSENCE(undefined, item)))
            value.forEach(item => {
              if (item.motif === 0) {
                dispatch(UPDATE_CONGES_USER(item))
              }
            })
          } else {
            if (data.data === 'die') {
              dispatch(USER_LOGOUT(false))
            }
            print_error(data.response)
          }
        })
        .catch(() => {
          print_error()
        })
    }
  }
}
