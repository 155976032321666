import dayjs from '../Libraries/dayjs'
import store from '../Redux/Store/Store'
import {
  start_case,
  upper_case
} from './Strings'
import {
  get_conges_number_length
} from './Absences'
import {
  get_id
} from './Functions'

export function get_data(dates, creneaux) {
  let ik = []
  let hours = []
  let cutter = []
  let holidays = []
  const equipe = store.getState().equipe.equipe
  const absences = store.getState().absences.absences
  const settings = store.getState().settings.variables
  const supplementaires = store.getState().heures.heures
  const fonctions = store.getState().fonctions.fonctions
  const indemnites = store.getState().indemnites.indemnites
  const data = equipe.map(item => {
    let nombre
    let absence
    let supp = 0
    let array = []
    let conges = 0
    let heures = 0
    let kilometres = 0
    creneaux.forEach(object => {
      if (object.equipe.includes(item.id)) {
        const end = dayjs(object.endDate)
        const start = dayjs(object.startDate)
        const duration = dayjs.duration(end.diff(start))
        const hours = duration.asHours()
        const holiday = absences.filter(element =>
          (start.unix() >= element.debut) &&
          (end.unix() <= element.fin) &&
          (element.user === item.id) &&
          (element.status === 1)
        )
        if (holiday.length === 0) {
          array.push({
            heures: hours,
            type: 'heures',
            fin: dayjs(object.endDate).format('DD/MM/YYYY HH:mm'),
            debut: dayjs(object.startDate).format('DD/MM/YYYY HH:mm')
          })
          heures += hours
        } else if (holiday[0].motif === 1) {
          array.push({
            heures: hours,
            type: 'heures',
            motif: settings.absence.motifs[holiday[0].motif],
            fin: dayjs(object.endDate).format('DD/MM/YYYY HH:mm'),
            debut: dayjs(object.startDate).format('DD/MM/YYYY HH:mm')
          })
          heures += hours
        } else {
          array.push({
            heures: 0,
            type: 'heures',
            motif: settings.absence.motifs[holiday[0].motif],
            fin: dayjs(object.endDate).format('DD/MM/YYYY HH:mm'),
            debut: dayjs(object.startDate).format('DD/MM/YYYY HH:mm')
          })
        }
      }
    })
    absences.filter(object =>
      (dayjs.unix(object.debut).isSameOrBefore(dates[1])) &&
      (dayjs.unix(object.fin).isSameOrAfter(dates[0])) &&
      (item.id === object.user) &&
      (object.status === 1)
    ).forEach(object => {
      nombre = object.nombre
      if (
        (dayjs.unix(object.debut).isBefore(dates[0])) &&
        (dayjs.unix(object.fin).isAfter(dates[1]))
      ) {
        nombre = get_conges_number_length(
          dates[0],
          dates[1],
          object.duree
        )
        absence = {
          ...object,
          fin: parseInt(dates[1].unix()),
          debut: parseInt(dates[0].unix())
        }
        cutter.push(
          {
            ...object,
            id: get_id(),
            fin: parseInt(dates[0].unix()) - 1,
            nombre: get_conges_number_length(
              dayjs.unix(object.debut),
              dates[0].subtract(1, 'second'),
              object.duree
            )
          },
          {
            ...object,
            id: get_id(),
            debut: parseInt(dates[1].unix()) + 1,
            nombre: get_conges_number_length(
              dates[1].add(1, 'second'),
              dayjs.unix(object.fin),
              object.duree
            )
          }
        )
      } else {
        if (dayjs.unix(object.debut).isBefore(dates[0])) {
          nombre = get_conges_number_length(
            dates[0],
            dayjs.unix(object.fin),
            object.duree
          )
          absence = {
            ...object,
            debut: parseInt(dates[0].unix())
          }
          cutter.push(
            {
              ...object,
              id: get_id(),
              fin: parseInt(dates[0].unix()) - 1,
              nombre: get_conges_number_length(
                dayjs.unix(object.debut),
                dates[0].subtract(1, 'second'),
                object.duree
              )
            }
          )
        } else {
          absence = object
        }
        if (dayjs.unix(object.fin).isAfter(dates[1])) {
          nombre = get_conges_number_length(
            dayjs.unix(object.debut),
            dates[1],
            object.duree
          )
          absence = {
            ...object,
            fin: parseInt(dates[1].unix())
          }
          cutter.push(
            {
              ...object,
              id: get_id(),
              debut: parseInt(dates[1].unix()) + 1,
              nombre: get_conges_number_length(
                dates[1].add(1, 'second'),
                dayjs.unix(object.fin),
                object.duree
              )
            }
          )
        } else {
          absence = object
        }
      }
      holidays.push({
        id: object.id,
        nombre: nombre,
        fin: absence.fin,
        user: object.user,
        debut: absence.debut,
        motif: absence.motif
      })
      if (absence.motif !== 1) {
        array.push({
          ...absence,
          type: 'conges',
          nombre: nombre,
          duree: settings.absence.conges[object.duree],
          motif: settings.absence.motifs[object.motif],
          fin: dayjs.unix(absence.fin).format('DD/MM/YYYY'),
          debut: dayjs.unix(absence.debut).format('DD/MM/YYYY')
        })
        conges += nombre
      }
    })
    indemnites.filter(object =>
      dayjs.unix(object.date).isSameOrAfter(dayjs(dates[0])) &&
      dayjs.unix(object.date).isSameOrBefore(dates[1]) &&
      (item.id === object.user) &&
      (object.status === 1)
    ).forEach(object => {
      ik.push({
        id: object.id,
        user: item.id
      })
      array.push({
        type: 'ik',
        nombre: object.nombre,
        fin: dayjs.unix(object.date).format('DD/MM/YYYY'),
        debut: dayjs.unix(object.date).format('DD/MM/YYYY')
      })
      kilometres += object.nombre
    })
    supplementaires.filter(object =>
      (object.debut >= dayjs(dates[0]).unix()) &&
      (object.fin <= dayjs(dates[1]).unix()) &&
      (object.recuperation === 0) &&
      (item.id === object.user) &&
      (object.status === 1)
    ).forEach(object => {
      hours.push({
        id: object.id,
        user: item.id
      })
      const fin = dayjs.unix(object.fin)
      const debut = dayjs.unix(object.debut)
      const value = parseFloat((dayjs.unix(object.fin) - dayjs.unix(object.debut)) / 60000)
      const nombre = (object.nombre === 0)
        ? (object.sens === 1)
          ? -value
          : value
        : (object.sens === 1)
          ? -object.nombre
          : object.nombre
      array.push({
        type: 'supp',
        nombre: nombre,
        fin: fin.format('DD/MM/YYYY HH:mm'),
        debut: debut.format('DD/MM/YYYY HH:mm'),
        motif: settings.heure.motifs[object.motif]
      })
      supp += nombre
    })
    return {
      supp: supp,
      id: item.id,
      details: array,
      heures: heures,
      conges: conges,
      color: item.color,
      profil: item.profil,
      mensuel: item.heures,
      indemnites: kilometres,
      nom: upper_case(item.nom),
      prenom: start_case(item.prenom),
      fin: dayjs(dates[1]).format('DD/MM/YYYY'),
      debut: dayjs(dates[0]).format('DD/MM/YYYY'),
      fonction: fonctions.find(object => object.id === item.fonction).fonction
    }
  })
  return {
    ik: ik,
    data: data,
    hours: hours,
    cutter: cutter,
    holidays: holidays
  }
}
