import React from 'react'
import Form from '../Includes/Wrapper/Form'
import styles from './AbsenceModalContent.module.css'
import InputMotif from '../Includes/Input/InputMotif'
import SelectDuree from '../Includes/Select/SelectDuree'
import SelectMotif from '../Includes/Select/SelectMotif'
import SelectSalarie from '../Includes/Select/SelectSalarie'
import DateRangePicker from '../Includes/Picker/DateRangePicker'
import {
  useSelector
} from 'react-redux'
import {
  CheckLong
} from '../../Functions/Check'
import {
  is_holiday
} from '../../Functions/Absences'

const AbsenceModalContent = ({
  state,
  Update
}) => {
  const user = useSelector(state => state.login.user)
  const settings = useSelector(state => state.settings.variables)
  const Motif = (value) => {
    Update('texte', value)
    Update('error', (
      (value !== '') &&
      (!CheckLong(value))
    ))
  }
  return (
    <Form full={true}>
      {
        (user.profil === 'administrateur')
          ? <SelectSalarie
              value={state.salarie}
              setValue={(value) => Update('salarie', value)}
            />
          : null
      }
      <SelectMotif
        value={state.motif}
        array={settings.absence}
        setValue={(value) => Update('motif', value)}
      />
      {
        (state.motif === (settings.absence.motifs.length - 1))
          ? <InputMotif
              setValue={Motif}
              error={state.error}
              value={state.texte}
            />
          : null
      }
      <SelectDuree
        value={state.duree}
        disabled={state.disabled}
        setValue={(value) => Update('duree', value)}
      />
      <DateRangePicker
        value={state.dates}
        shouldDisableDate={(date) => is_holiday(date)}
        slotProps={{
          fieldSeparator: {
            className: 'picker-separator-dates'
          }
        }}
        onChange={(value) => {
          Update('dates', [
            value[0],
            (value[1] !== null)
              ? value[1]
              : value[0]
          ])
        }}
      />
      <div className={styles.selection}>
        {
          (state.select > 0)
            ? <span>Votre sélection : <b>{state.select} jour{(state.select > 1) ? 's' : ''}</b></span>
            : <span className="error-absence">Vous devez sélectionner au moins un jour</span>
        }
      </div>
    </Form>
  )
}

export default AbsenceModalContent
