import React from 'react'
import Bulle from '../Wrapper/Bulle'
import TableCellMobile from './TableCellMobile'
import styles from './TableCellMotif.module.css'
import TableCellDesktop from './TableCellDesktop'
import {
  useSelector
} from 'react-redux'
import {
  Info
} from '@mui/icons-material'

const TableCellMotif = ({
  item,
  label,
  device,
  target
}) => {
  const settings = useSelector(state => state.settings.variables)
  const last = (item.motif === (settings[target].motifs.length - 1))
  return (device.mobile)
    ? <TableCellMobile label={label}>
        {
          (last)
            ? item.texte
            : item.cause
        }
      </TableCellMobile>
    : <TableCellDesktop max={150}>
        {
          (last)
            ? <>
                {item.cause}
                <Bulle
                  arrow={true}
                  placement="top"
                  title={item.texte}
                >
                  <Info className={styles.motif}/>
                </Bulle>
              </>
            : item.cause
        }
      </TableCellDesktop>
}

export default TableCellMotif
