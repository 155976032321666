import dayjs from '../Libraries/dayjs'
import {
  format_date
} from '../Functions/Dates'

const {
  REACT_APP_DEV,
  REACT_APP_DNS,
  REACT_APP_BETA,
  REACT_APP_DEMO,
  REACT_APP_PATH,
  REACT_APP_PROD,
  REACT_APP_QUALIF,
  REACT_APP_MAINTENANCE_FIN,
  REACT_APP_MAINTENANCE_DEBUT
} = process.env

export const config = {
  dev: REACT_APP_DEV,
  dns: REACT_APP_DNS,
  beta: REACT_APP_BETA,
  demo: REACT_APP_DEMO,
  path: REACT_APP_PATH,
  prod: REACT_APP_PROD,
  qualif: REACT_APP_QUALIF,
  fin: REACT_APP_MAINTENANCE_FIN,
  debut: REACT_APP_MAINTENANCE_DEBUT,
  live: (
    (REACT_APP_BETA === '1') ||
    (REACT_APP_PROD === '1')
  ),
  test: (
    (REACT_APP_QUALIF === '1') ||
    (REACT_APP_DEMO === '1') ||
    (REACT_APP_DEV === '1')
  )
}

export const steps = [
  "Créez votre compte",
  "Choisissez votre formule",
  "Renseignez vos données bancaires"
]

export const absences = {
  done: {
    year: "N-1"
  },
  current: {
    year: "N"
  }
}

export const ouverture = {
  am: {
    debut: {
      h: null,
      m: null
    },
    fin: {
      h: null,
      m: null
    }
  },
  pm: {
    debut: {
      h: null,
      m: null
    },
    fin: {
      h: null,
      m: null
    }
  }
}

export const filtres = {
  absences: {
    motif: -1,
    status: -1,
    salarie: -1,
    dates: [null, null]
  },
  heures: {
    motif: -1,
    status: -1,
    salarie: -1,
    dates: [null, null]
  },
  indemnites: {
    status: -1,
    salarie: -1,
    dates: [null, null]
  },
  companies: {
    beta: -1,
    code: -1,
    last: -1,
    type: -1,
    status: -1,
    date: null,
    error: false,
    planning: -1,
    abonnement: -1,
    engagement: -1
  },
  users: {
    login: -1,
    logged: -1,
    profil: -1,
    status: -1,
    companie: -1
  },
  codes: {
    type: -1
  }
}

export const aide = [
  {
    admin: false,
    icon: "planning",
    label: "Planning"
  },
  {
    admin: true,
    icon: "week",
    label: "Semaines"
  },
  {
    admin: false,
    icon: "absences",
    label: "Absences"
  },
  {
    admin: false,
    icon: "heures",
    label: "Heures"
  },
  {
    admin: false,
    icon: "car",
    label: "Indemnités"
  },
  {
    admin: true,
    icon: "chart",
    label: "Compatibilité"
  },
  {
    admin: true,
    icon: "team",
    label: "Équipe"
  },
  {
    admin: true,
    icon: "computer",
    label: "Offices"
  },
  {
    admin: false,
    icon: "divers",
    label: "Divers"
  }
]

export const variables = {
  path: {
    link: "/",
    api: "/api/",
    img: "/img/",
    icon: "/icon/",
    videos: "/videos/"
  },
  page: {
    api: "api.php",
    user: "user.php",
    token: "token.php",
    stripe: "stripe.php",
    planning: "planning.php"
  },
  url: {
    server: config.dns + config.path,
    prod: [
      "https://planipo.fr",
      "https://www.planipo.fr"
    ],
    beta: [
      "https://beta.planipo.fr",
      "https://www.beta.planipo.fr"
    ]
  },
  mail: {
    maximum: 20,
    contact: "contact@planipo.fr",
    support: "support@planipo.fr",
    texte: "Bonjour,\n\n\n\nCordialement\nL'équipe Planipo."
  },
  mui: {
    key: "e89fc87a03b169f2d8e0a4e92fc844cfTz03MTc0NSxFPTE3MjIyNTgxMjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  },
  stripe: {
    public: (config.test)
      ? "pk_test_51Ls5o9GXUJ2JVPHwG9hRLFfgoJ6FtS2iJJWnlsGnM7PBdTHT3QIuglndOOuNcMxf1lWaao0uRwIdekuWCcU8193400guqkIZFM"
      : "pk_live_51Ls5o9GXUJ2JVPHwx1dRrDvRtf4r3ytM3ljULrooCB7jYsoIAxKxWCCdo8BRAQHaw59p0HSE8hAwzgrTxWQSSpAP00aZFg8jCj",
    card: {
      succeeded: (config.test)
        ? "4242424242424242"
        : "",
      failed: (config.test)
        ? "4000000000009995"
        : "",
      action: (config.test)
        ? "4000002500003155"
        : ""
    }
  },
  date: {
    step: 0.25,
    debut: {
      heure: 7,
      minute: 0
    },
    fin: {
      heure: 22,
      minute: 0
    }
  },
  taille: {
    texte: {
      minimum: 2,
      maximum: 40
    },
    password: {
      minimum: 8,
      maximum: 32
    },
    mail: {
      minimum: 6,
      maximum: 100
    },
    sujet: {
      minimum: 2,
      maximum: 40
    },
    message: {
      minimum: 20,
      maximum: 5000
    }
  },
  table: {
    small: [5],
    mobile: [20],
    large: [100, 200],
    normal: [10, 20, 50]
  },
  toast: {
    close: 5000,
    delay: 30000,
    error: "Une erreur est survenue"
  },
  empty: {
    string: "-",
    users: "Aucun utilisateur",
    companies: "Aucune entreprise"
  },
  absence: {
    fin: 86399,
    milieu: 46800,
    duree: [50400, 43200]
  },
  heure: {
    step: 15
  },
  event: {
    accueil: 3
  }
}

export const initial = {
  fonction: {
    fonction: ""
  },
  equipe: {
    nom: "",
    mail: "",
    temps: 0,
    prenom: "",
    heures: 151.67,
    profil: "utilisateur",
    conges: {
      done: 0,
      current: 0
    }
  },
  stripe: {
    empty: true,
    complete: false,
    error: undefined
  },
  creneau: {
    am: {
      debut: {
        heure: 9,
        minute: 0
      },
      fin: {
        heure: 12,
        minute: 30
      }
    },
    pm: {
      debut: {
        heure: 14,
        minute: 0
      },
      fin: {
        heure: 19,
        minute: 30
      }
    }
  },
  absence: {
    motif: 0,
    duree: 2,
    texte: "",
    dates: [
      dayjs().startOf('day'),
      dayjs().startOf('day')
    ]
  },
  heure: {
    sens: 0,
    motif: 0,
    texte: "",
    nombre: 120,
    recuperation: 0,
    date: dayjs().startOf('day'),
    debut: {
      heure: 9,
      minute: 0
    },
    fin: {
      heure: 12,
      minute: 30
    }
  },
  indemnite: {
    nombre: 20,
    date: dayjs().startOf('day')
  },
  empty: {
    horaires: []
  },
  semaine: {
    horaires: [
      {
        equipe: [],
        ...format_date({
          jour: 0,
          debut: {
            heure: 9,
            minute: 0
          },
          fin: {
            heure: 12,
            minute: 30
          }
        })
      },
      {
        equipe: [],
        ...format_date({
          jour: 0,
          debut: {
            heure: 14,
            minute: 0
          },
          fin: {
            heure: 19,
            minute: 30
          }
        })
      },
      {
        equipe: [],
        ...format_date({
          jour: 1,
          debut: {
            heure: 9,
            minute: 0
          },
          fin: {
            heure: 12,
            minute: 30
          }
        })
      },
      {
        equipe: [],
        ...format_date({
          jour: 1,
          debut: {
            heure: 14,
            minute: 0
          },
          fin: {
            heure: 19,
            minute: 30
          }
        })
      },
      {
        equipe: [],
        ...format_date({
          jour: 2,
          debut: {
            heure: 9,
            minute: 0
          },
          fin: {
            heure: 12,
            minute: 30
          }
        })
      },
      {
        equipe: [],
        ...format_date({
          jour: 2,
          debut: {
            heure: 14,
            minute: 0
          },
          fin: {
            heure: 19,
            minute: 30
          }
        })
      },
      {
        equipe: [],
        ...format_date({
          jour: 3,
          debut: {
            heure: 9,
            minute: 0
          },
          fin: {
            heure: 12,
            minute: 30
          }
        })
      },
      {
        equipe: [],
        ...format_date({
          jour: 3,
          debut: {
            heure: 14,
            minute: 0
          },
          fin: {
            heure: 19,
            minute: 30
          }
        })
      },
      {
        equipe: [],
        ...format_date({
          jour: 4,
          debut: {
            heure: 9,
            minute: 0
          },
          fin: {
            heure: 12,
            minute: 30
          }
        })
      },
      {
        equipe: [],
        ...format_date({
          jour: 4,
          debut: {
            heure: 14,
            minute: 0
          },
          fin: {
            heure: 19,
            minute: 30
          }
        })
      },
      {
        equipe: [],
        ...format_date({
          jour: 5,
          debut: {
            heure: 9,
            minute: 0
          },
          fin: {
            heure: 12,
            minute: 30
          }
        })
      },
      {
        equipe: [],
        ...format_date({
          jour: 5,
          debut: {
            heure: 14,
            minute: 0
          },
          fin: {
            heure: 19,
            minute: 30
          }
        })
      }
    ]
  }
}
